import React from 'react';
import {Col, Grow, Row} from "../Flex";
import buschImg from "../../assets/logo/busch-otto-iot-logo.png"
import {useTranslation} from "react-i18next";
import _ from "lodash";
import SplitPanel from "../SplitPanel";
import moment from "moment";
import ConnectionStatus from "../ConnectionStatus";

interface IotDevice {
    deviceStatus: string,
    externalLink: string,
    isIoTEnabled: boolean,
    lastActivityAt: string,
    signalStrength: string,
}

interface Props {
    iotDevice: IotDevice,
}

const BuschConnectionSplitPanel = ({iotDevice}: Props) => {
    const {t } = useTranslation();

    const lastActivityDescription = `${t("Last activity")} ${moment(_.get(iotDevice,'lastActivityAt')).fromNow()}`;

    const panel = <Row className={'split-panel mb-15'}>
        <Col className={'left-content-wrapper flex-align-items-center flex-center white-bg'}>
            <Row>
                <img src={buschImg} height={50} />
            </Row>
        </Col>
        <Grow className={'right-content-wrapper flex flex-align-items-space-between flex-col'}>
            <Row className={'flex-row flex-center'}>
                <Grow className={'flex-col'}>
                    <span className={'title'}>
                        {t("OTTO Dashboard")}
                    </span>
                    <span className={'description'}>
                        {lastActivityDescription}
                    </span>
                </Grow>
                <div className={"right-content-end-addition"}>
                    <ConnectionStatus
                        signalStrength={_.get(iotDevice, 'signalStrength')}
                        networkDescription={_.get(iotDevice, 'networkDescription')}
                    />
                </div>
            </Row>
        </Grow>
    </Row>

    if (iotDevice.url) {
        return <a href={iotDevice.url} target="_blank" rel="noopener noreferrer">
            {panel}
        </a>
    } else {
        return panel
    }

}

export default BuschConnectionSplitPanel;
