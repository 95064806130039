import React from 'react';
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

interface Props {
    signalStrength?: "NONE" | "WEAK" | "MODERATE" | "STRONG",
    networkDescription?: string
}

const ConnectionStatus = ({signalStrength, networkDescription}: Props) => {

    const getSignalStrengthIcon = (signalStrength: string) => {
        switch (signalStrength) {
           case "WEAK":
                return {
                    color: "#EA463A",
                    iconName: 'signal-cellular-1'
                };
            case "MODERATE":
                return {
                    color: "#F3AC41",
                    iconName: 'signal-cellular-2'
                };
            case "STRONG":
                return {
                    color: "#00CC1D",
                    iconName: 'signal-cellular-3'
                };
            default:
                return {
                    color: "grey",
                    iconName: 'signal-cellular-off'
                };
        }
    }

    return (
        <div className={"flex-row flex-center"}>
            <span className={"bold"}>{networkDescription}</span>
            <MaterialCommunityIcon
                style={{fontSize: 20, color: getSignalStrengthIcon(signalStrength).color}}
                className={"icon ml-5"}
                name={getSignalStrengthIcon(signalStrength).iconName}
            />
        </div>
    );
}

export default ConnectionStatus;
