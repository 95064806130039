import React, {Component} from "react";
import {Select} from "antd";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";

type Props = {
    onChange: Function
}

class ComponentProductSelect extends Component<Props> {

    render() {
        const {t, onChange} = this.props;

        return <Select defaultValue="product" style={{width: '100%'}}
                       onChange={onChange}
        >
            <Select.Option value='product'>{t('Product')}</Select.Option>
            <Select.Option value="semiconductorTool">{t('semiconTool')}</Select.Option>
            <Select.Option value="semiconductorChamber">{t('semiconChamber')}</Select.Option>
        </Select>
    }
}

export default compose(
    withTranslation(),
)(ComponentProductSelect);
