// @flow
import React, {Component} from "react";
import {Input, Select, Row, Col} from "antd";
import * as _ from "lodash";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import ProductSearchResultList from "./ProductSearchResultList";
import ComponentProductSelect from "../ComponentProductSelect";

type Props = {
    query: string,
    onSelect: ?Function,
    onExtraLink: ?Function,
    handleSearch: Function,
    handleFilter: Function,
    debounce: number,
    componentTypeIndex: null | string,
}

class ProductSearch extends Component<Props> {

    static defaultProps = {
        debounce: 100
    };

    constructor(props) {
        super(props);
        this.handleSearch = _.debounce(this.props.handleSearch, props.debounce);
    }



    render() {
        const {onSelect, t, onExtraLink, handleFilter, componentTypeIndex, query, handleSearch} = this.props;

        const placeholder = {
            null: t('Search for product name or catalog number'),
            'semiconductorTool': t('Search for semiconTool name'),
            'semiconductorChamber': t('Search for semiconChamber name')
        }

        return <div data-cy={'component-create-product-input'}>
            <Row>
                <Col flex="auto">
                    <Input
                        onChange={(e) => {
                            handleSearch(e.target.value)
                        }}
                        placeholder={placeholder[componentTypeIndex]}
                    />
                </Col>
                <Col flex="150px">
                    <ComponentProductSelect onChange={(value) => {
                        handleFilter(value !== 'product' ? value : null);
                    }}/>
                </Col>
            </Row>
            <span className={'pr-5'}>{t("Can't find what you're looking for?")}</span><a onClick={() => onExtraLink(componentTypeIndex)}>{t("Create a new component.")}</a>
            <ProductSearchResultList query={query} componentTypeIndex={componentTypeIndex} onSelect={onSelect}/>
        </div>;
    }

}

export default compose(
    withTranslation(),
)(ProductSearch);
