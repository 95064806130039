// @flow
import React, {Component} from "react"
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import product from "../../graphql/queries/productWithComponentTypeAttributes.graphql"
import createComponent from "../../graphql/mutations/createComponent.graphql"
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Checkbox, Col, Row} from "antd";
import ComponentInfoBox from "../ComponentInfoBox";
import Label from "../Utilities/Label";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import withProfile from "../../hoc/withProfile";
import isServiceProvider from "../../utils/isServiceProvider";
import {LeftOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import ComponentTypeSelect from "./ComponentTypeSelect";

type Props = {
    parentComponentId: string,
    componentTypeIndex: string,
    onComponentTypeChange: Function,
    productId: number,
    onCreated: Function,
    product: {
        name: string,
        catalogNo: string,
        manufacturer: string,
        manufacturerId: number,
    },
    getProduct: {
        loading: boolean,
        product: any,
    },
    createComponent: Function,
    t: Function,
}

type State = {
    addSubcomponent: boolean
}

class ComponentCreateForm extends Component<Props, State> {

    addSubcomponentDefaults = ['semiconductorTool', 'machine', 'componentProduct']

    constructor(props) {
        super(props);
        this.state = {
            addSubcomponent: this.addSubcomponentDefaults.includes(this.props.componentTypeIndex),
        };
    }

    values = {};

    render() {

        const {
            attributeQuery,
            getProduct,
            t,
            createComponent,
            parentComponentId,
            productId,
            onCreated,
            product,
            client,
            history,
            componentTypeIndex,
            onComponentTypeChange
        } = this.props;

        const {addSubcomponent} = this.state;

        let productObject = null;

        if (product && Object.keys(product).length) {
            productObject = {...this.props.product};
            if (productId) {
                productObject = {
                    name: getProduct.product.name,
                    catalogNo: getProduct.product.catalogNo
                }
            }
            if (isNaN(parseInt(productObject.manufacturerId))) {
                _.set(productObject, 'manufacturer', {
                    name: productObject.manufacturerId
                });
                delete productObject.manufacturerId;
            }
        }

        const attributes = _.get(attributeQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);
        const mainFields = ["name", "serialNo", ...attributes.filter(item => _.findIndex(item.rules, {name: 'required'}) !== -1).map(item => item.index)];

        if (isServiceProvider(this.props.resources)) {
            mainFields.push("clientId");
        }

        return <Col className={'ph-10 pt-10'}>
            <Row className={'pb-15'}>
                <LeftOutlined style={{fontSize: 20}} onClick={() => history.goBack()}/>
                <h3 className={'ph-5'}>{t('Add Infrastructure Unit')}</h3>
            </Row>
            <Label>{t('Parent Node')}</Label>
            <div className={'lighter-grey-bg border-radius-5 border'} style={{marginBottom: '24px'}}>
                <ComponentInfoBox
                    componentId={parentComponentId}/>
            </div>
            <Label>{t('Details')}</Label>
            {!productId && <ComponentTypeSelect componentTypeIndex={componentTypeIndex} onChange={(index) => {
                onComponentTypeChange(index);
            }}/>}
            <AttributesInlineForm
                useSubmit
                layout={'vertical'}
                collapse={mainFields}
                mutation={(options) => {
                    return new Promise((resolve, reject) => {
                        createComponent(options)
                            .then((res) => {
                                return client.clearStore().then(() => res);
                            })
                            .then(resolve)
                            .catch(reject)
                    })
                }}
                onSubmit={(res) => onCreated(res, addSubcomponent)}
                translateMutationOptions={({values}) => {
                    // VSM-11812 - productId wird nicht mehr nur vorausgewählt über die params übergeben, sondern kann auch im Create-Form ausgewählt werden
                    const productIdFromValues = _.get(values, 'productId')

                    Object.keys(values).map((key) => {
                        if (values[key] === "" || values[key] === null || key === "productId") {
                            delete values[key]
                        }
                    });

                    return {
                        variables: {
                            parentId: parentComponentId,
                            componentCreateInput: {
                                componentTypeIndex: componentTypeIndex,
                                productId: productIdFromValues || productId,
                                attributes: values,
                                product: productObject ? productObject : undefined,
                            }
                        }
                    }
                }}
                submitTitle={t('Add')}
                attributes={attributes}
                beforeSubmit={ <Row style={{cursor:'pointer'}} onClick={() => {
                    this.setState({addSubcomponent: !addSubcomponent})
                }}>
                    <Checkbox checked={addSubcomponent}/>
                    <p className={'ph-10'}>{t('Add subcomponent')}</p>
                </Row>}
            />

        </Col>;
    }

}

export default compose(
    withRouter,
    withTranslation(),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'attributeQuery',
        options: props => ({
            variables: {
                index: 'component',
                options: {
                    parentId: props.parentComponentId,
                    componentTypeIndex: props.componentTypeIndex,
                    productId: props.productId
                }
            }

        }),
    }),
    waitWhileLoading('attributeQuery'),

    graphql(product, {
        name: 'getProduct',
        skip: props => !props.productId,
        options: props => ({
            variables: {
                id: props.productId
            }
        }),
    }),
    waitWhileLoading('getProduct', null, {optional: true}),

    graphql(createComponent, {
        name: 'createComponent',
    }),

    withProfile(),
    withApollo,
)(ComponentCreateForm);
